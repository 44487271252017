/*
 * @Author: your name
 * @Date: 2021-07-27 10:53:02
 * @LastEditTime: 2021-09-02 18:58:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/services/mater.js
 */
import request from '@/utils/request'
import API from '@/apis/api-types'
export function getArticleList({
  pageNo = 1,
  pageSize = 20,
  categoryId = '',
  searchKey = '',
  knowledgeType,
  tagId = '',
  isQueryAll = true
}) {
  return request({
    method: 'get',
    url: API.getArticleList,
    params: {pageNo, pageSize, categoryId, searchKey, knowledgeType, tagId, isQueryAll}
  })
}
export function getMaterialExamList({categoryId = '', answerType = '', pageNo = 1, pageSize = 20, searchKey = '', type = 1}) {
  return request({
    method: 'get',
    url: API.getMaterialExamList,
    params: {pageNo, pageSize, categoryId, answerType, searchKey, type}
  })
}
export function getVideoPlayUrl({ videoId = '', courseId = '' }) {  // 获取单个视频播放地址
  return request({
    url: API.videosPlayUrl,
    method: 'get',
    params: {videoId, courseId}
  })
}
export function getVideoUrl(videoId = '') {  // 获取单个视频播放地址
  return request({
    url: API.getVideoUrl,
    method: 'get',
    params: {videoId}
  })
}
export function getExamDetail(examId = '') {     // 问题详情
  return request({
    url: API.getExamDetail,
    method: 'get',
    params: {examId}
  })
}
export function updateExam(data) {
  // 问题详情
  return request({
    url: API.updateExam,
    method: 'post',
    data
  })
}
export function getVideoList(params) {
  return request({
    url: API.getVideoList,
    method: 'get',
    params
  })
}
export function getCategoryList(params) {
  return request({
    url: API.getCategoryList,
    method: 'get',
    params
  })
}
export function getExamList(data) {
  return request({
    url: API.getExamList,
    method: 'post',
    data
  })
}
export function createCategory(params) {
  return request({
    url: API.createCategory,
    method: 'post',
    params
  })
}
export function deleteVideo(data) {
  return request({
    url: API.deleteVideo,
    method: 'post',
    params: data
  })
}
export function deleteExam(data) {
  return request({
    url: API.deleteExam,
    method: 'post',
    params: data
  })
}
export function deleteCategory(params) {
  return request({
    url: API.deleteCategory,
    method: 'post',
    params
  })
}

export function categoryUpdate(params) {
  return request({
    url: API.categoryUpdate,
    method: 'post',
    params
  })
}

export function getArticleDetail(params) {
  return request({
    url: API.getArticleDetail,
    method: 'get',
    params
  })
}
export function updateArticle(data) {
  return request({
    url: API.updateArticle,
    method: 'post',
    data
  })
}

export function deleteArticle(params) {
  return request({
    url: API.deleteArticle,
    method: 'post',
    params
  })
}

export function fileUpload(data) {
  return request({
    url: API.fileUpload,
    method: 'post',
    data
  })
}
export function createPDF(data) {
  return request({
    url: API.createPDF,
    method: 'post',
    data
  })
}
export function immFormat(params) {
  return request({
    url: API.immFormat,
    method: 'get',
    params
  })
}
export function createArticle(data) {
  return request({
    url: API.createArticle,
    method: 'post',
    data
  })
}
