<template>
  <div v-loading="isLoading" class="scene-slide-right">
    <div>
      <el-row
        v-if="similarExamList.length > 0"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <el-row type="flex" align="middle" class="similar-title">
          <i class="el-icon-warning warn-tips" />
          <span class="red-text">发现题目高度相似，是否替换？</span>
        </el-row>
      </el-row>
      <div
        v-for="(item, index) in similarExamList"
        :key="item.examId"
        class="similar-exam-wrapper"
      >
        <div v-if="index === showIndex">
          <exam-item :item="item" :index="index + 1" />
          <div class="similar-bottom">
            <el-row type="flex" justify="space-between">
              <div class="red-text">相似度：{{ item.similar || 0 }}%</div>
              <div class="change-exam" @click="changeNextExam">
                <i class="el-icon-refresh" />
                换一个
              </div>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-button
                plain
                class="similar-btn"
                type="primary"
                @click="onSave(currentExamItem)"
              >
                不用替换
              </el-button>
              <el-button
                type="primary"
                class="similar-btn"
                @click="onReplaceExam(item)"
              >
                一键替换
              </el-button>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
// scss
</style>
