<!--
 * @Author: your name
 * @Date: 2021-05-25 19:34:34
 * @LastEditTime: 2023-03-06 10:14:32
 * @LastEditors: fangteng fangteng@yaomaitong.cn
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/components/ExamPre/index.vue
-->
<template>
  <ksh-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false">
    <div slot="title">题目预览</div>
    <div class="dialog-content-box">
      <div class="title">{{ questionObj[itemObjMap.title || 'title'] | titleFilter }}（{{ questionObj.examTypeEnum | examTypeFilter }}{{ questionObj.leastAnswerCount ? '，至少选'+ questionObj.leastAnswerCount + '项' : '' }}）</div>
      <div v-if="questionObj.examRichTextContent" v-safe-html="questionObj.examRichTextContent" style="width: 300px;" />
      <div v-if="questionObj.examTypeEnum === 'scale'">
        <div class="flex-between pdg-tb10">
          <div class="flex-box align-center" />
          <div
            v-for="(item,index) in questionObj[itemObjMap.answers || 'answers']"
            :key="index"
            class="flex-box align-center"
          >
            <span>{{ item[(itemObjMap.answersItem && itemObjMap.answersItem.answer) || 'answer'] }}</span>
          </div>
        </div>
        <el-row
          v-for="(v,i) in questionObj[itemObjMap.scaleQuestionList || 'scaleQuestionList']"
          :key="i"
          type="flex"
          align="middle"
          class="exam-answer-wrapper flex-between border-bottom"
        >
          <div class="flex-box">{{ v }}</div>
          <div v-for="(t,s) in questionObj[itemObjMap.answers || 'answers']" :key="s" class="flex-center flex-box">
            <div class="answer-item" />
          </div>
        </el-row>
      </div>
      <div v-else-if="questionObj.examTypeEnum === 'blank_fill' && isShowCorrect" class="mgn-t10">
        推荐/正确选项：
        <span v-for="(v,i) in questionObj.answers" :key="i">填空{{ i+1 }}：{{ v.answer || v.title }}  </span>
      </div>
      <div v-else-if="questionObj.examTypeEnum === 'text_fill' && isShowCorrect" class="text-fill" />
      <template v-for="(item,index) in questionObj[itemObjMap.answers || 'answers']" v-else>
        <div :key="index" class="mgn-t10">
          <span>{{ answerIndex[index] }}. </span>
          <span>{{ item[(itemObjMap.answersItem && itemObjMap.answersItem.answer) || 'answer'] }}</span>
          <span v-if="isShowCorrect&&item[(itemObjMap.answersItem && itemObjMap.answersItem.isCorrect) || 'isCorrect']">（推荐/正确选项）</span>
        </div>
      </template>
      <div v-if="questionObj.examExpand" class="answer-item">
        推荐/正确选项：{{ questionObj.examTypeEnum === 'sort'? filterCorrect(questionObj.answers) : questionObj.examExpand }}
      </div>
      <div v-if="isShowCorrect && questionObj.examAnswerSettingVO" class="mgn-t10">
        答题设置：{{ questionObj.examAnswerSettingVO.isInOrder? '答案分顺序' : '答案不分顺序' }}
        {{ questionObj.examAnswerSettingVO.isIgnoreCase? '忽略大小写' : '区分大小写' }}
      </div>
      <div v-if="questionObj.examTypeEnum === 'text_fill' && isShowCorrect && questionObj.examAnswerSettingVO" class="answer-item">
        （答对以下关键词中任意{{ questionObj.examAnswerSettingVO.keywordCount }}个即为正确：
        <span v-for="(v,i) in questionObj.answers" :key="i">{{ i+1 }}：{{ v.answer || v.title }}  </span>）
      </div>
      <div v-if="questionObj.analysis" class="pdg-t10">题目解析：{{ questionObj[itemObjMap.analysis || 'analysis'] }}</div>
      <div
        v-if="questionObj[itemObjMap.tagInfos || 'tagInfos'] && questionObj[itemObjMap.tagInfos || 'tagInfos'].length"
        class="pdg-t10"
      >
        标签/关键信息：<ProductLabelKnowledge :selected-tag-list="questionObj[itemObjMap.tagInfos || 'tagInfos']" :disabled-ids="disabledIds" />
      </div>
    </div>
    <div slot="footer">
      <ksh-button
        :loading="btnLoading"
        @click="onClose"
      >
        关闭
      </ksh-button>
    </div>
  </ksh-dialog>
</template>

<script>
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'ExamPre',
  components: {
    ProductLabelKnowledge
  },
  filters: {
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    },
    examTypeFilter(type) {
      const obj = {
        single: '单选',
        multiple: '多选',
        text_fill: '问答',
        blank_fill: '填空',
        sort: '排序',
        scale: '量表'
      }
      return obj[type]
    }
  },
  props: {
    visible: {       // 是否显示，默认true
      type: Boolean,
      default: true
    },
    isShowCorrect: {
      type: Boolean,
      default: true
    },
    itemObj: {
      type: Object,
      default() {
        return {}
      }
    },
    itemObjMap: {
      type: Object,
      default() {
        return {
          title: '',    // 题目
          examTypeEnum: '',   // 类型single:单选
          answers: '', // 题目选项list
          answersItem: {    // 题目选项item
            answer: '',  // 题目选项item的内容
            isCorrect: false    // 题目推荐/正确选项
          },
          analysis: '',    // 题目解析
          tagInfo: ''    // 题目标签
        }
      }
    }
  },
  data() {
    return {
      btnLoading: false,
      answerIndex: Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      })

    }
  },
  computed: {
    questionObj() {
      return {
        ...this.itemObj
      }
    },
    filterCorrect() {
      return list => {
        const newList = JSON.parse(JSON.stringify(list))
        const strArr = []
        if (this.questionObj.examExpand) {
          const examExpandList = this.questionObj.examExpand.split(',')
          examExpandList.forEach(it => {
            newList.forEach((item, ind) => {
              item.titleStr = this.answerIndex[ind]
              if (item.examAnswerId == it) {
                strArr.push(item.titleStr)
              }
              return item
            })
          })
        }
        return strArr.join('、')
      }
    },
    disabledIds() {
      const arr = []
      const tagList = this.questionObj[this.itemObjMap.tagInfos || 'tagInfos']
      tagList?.map(v => {
        arr.push(v.tagId)
        v.knowledgePointInfos?.map(k => {
          arr.push(k.knowledgePointId)
        })
      })
      return arr
    }
  },
  methods: {

    onClose() {
      this.$emit('onClose', false)
    },
    onSubmit() {
      console.log(this.questionList, '------------------- onSubmit -------------------------')
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-content-box {
  max-height: 400px;
  text-align: left;
  overflow: scroll;
  color: #969696;
}
.title {
  font-weight: bold;
  color: #0f0f0f;
}
.exam-answer-wrapper {
  padding-bottom: 6px;
  padding-top: 6px;
  position: relative;
  .answer-opt-wrapper {
    position: absolute;
    left: calc(100% + 10px);
  }
  .opt-label {
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    color: $color-primary;
  }
  .answer-item {
    position: relative;
    color: #7784a1;
    width: 16px;
    height: 16px;
    border: 2px solid #e1e2e6;
    flex-shrink: 0;
    border-radius: 50%;
  }
}
.text-fill {
  border: 1px solid #e1e1e1;
  width: 300px;
  height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
