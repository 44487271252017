export const VUE_APP_GATEWAY_KSH = process.env.VUE_APP_GATEWAY_KSH // 科室会网关
export const VUE_APP_GATEWAY_QXS = process.env.VUE_APP_GATEWAY_QXS // 趣学术网关
export const VUE_APP_GATEWAY_CLOUD_BRAIN = process.env.VUE_APP_GATEWAY_CLOUD_BRAIN // 云脑网关
export const url = 'http://rap.yaomaitong.net/mockjsdata/9'
export default {
  // 微信申请签名
  wxConfig: `${VUE_APP_GATEWAY_QXS}/public/v1/wechat/token`,
  // wxAuth: `${VUE_APP_GATEWAY_QXS}/public/v1/lose-token`,
  // 首页
  dataCockpitBoardV1CreateUpdate: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/create-update`, // 创建或更新数据驾驶舱看板分类
  dataCockpitBoardModelShow: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/dataCockpitBoard-model-show`, // 数据驾驶舱面板
  dataCockpitBoardV1Del: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/del`, // 数据驾驶舱看板删除
  queryBoardList: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/query-board-list`, // 查看数据驾驶舱面板列表
  queryPushList: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/query-push-list`, // 数据服务列表
  dataCockpitBoardV1ModelRemove: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/model-remove`, // 报表移出看板
  dataCockpitBoardV1PushDetail: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/push-detail`, // 查看数据服务推送详情
  dataCockpitBoardV1AddSmartModel: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/add-smart-model`, // 添加报表到数据驾驶舱看板(支持选择多个驾驶舱看板)
  dataCockpitBoardV1SmartModelDetail: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/smartModel-detail`, // 查看单个报表详情
  dataCockpitBoardV1ModelParamSave: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/model-param-save`, // 保存报表参数条件
  dataCockpitBoardV1QueryPlaceholder: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/query-placeholder`, // 占位条件查询
  getRequestSqlMemberList: `${VUE_APP_GATEWAY_QXS}/common/get-request-sql-member-list`, // 智能报表请求数据元列表
  queryModelDataDirect: `${VUE_APP_GATEWAY_QXS}/dataService/query-model-data-direct`, // 直接查询报表结果
  queryReportModelDataDirect: `${VUE_APP_GATEWAY_QXS}/dataService/query-report-model-data-direct`, // 直接查询数据报告报表结果
  modelList: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/model-list`, // 报表名称和对应看板-编辑看板时可用
  dataCockpitBoardV1ColValue: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/colValue`, // 查询列的枚举值
  dataCockpitBoardIndexShow: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/dataCockpitBoard-index-show`, // 数据驾驶舱
  publishCategoryList: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/publish-category-list`, // 查询已发布报表分类
  dataCockpitBoardV1ModelAll: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/model-all`, // 查询已发布的报表-报表仓库
  organizationV1OrgList: `${VUE_APP_GATEWAY_QXS}/organization/v1/org-list`, // 通过一批组织id查询所有组织上级
  queryReportPush: `${VUE_APP_GATEWAY_QXS}/dataService/dataCockpitBoard/v1/query-report-push`, // 查看数据报告推送
  dataReportV1Detail: `${VUE_APP_GATEWAY_CLOUD_BRAIN}/dataReport/v1/detail`, // 根据报告id查询报告详情
  waitAttributeCodes: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/superior-assigned-wait-attribute-codes`, // 上级组织分配给我的，我待分配的任务分页列表
  // 个人信息
  userLogin: `${VUE_APP_GATEWAY_QXS}/factory/v1/auth/login`, // 厂家端登录
  userLoginOut: `${VUE_APP_GATEWAY_QXS}/factory/v1/auth/logout`,
  getUserInfo: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/info`,
  getRechargeList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/recharges`,
  getAccountMeetingList: `${VUE_APP_GATEWAY_KSH}/factory/v1/account/meetings`,
  getAccountMeetingDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/account/meeting/detail`,
  getAccountMeetingInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/account/meeting/info`,
  onAccountReCall: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/recall-child-gift`,
  getWxCodeImage: `${VUE_APP_GATEWAY_QXS}/public/v1/pc/wx-qrcode`, // 微信扫码登录二维码 0直播PC播客端;1组织登录;2微信绑定；3微信换绑；4敏感支付操作
  checkWxCodeResult: `${VUE_APP_GATEWAY_QXS}/public/v1/pc/scan-login-check`, // 微信扫码登录查询结果
  getAccountInfo: `${VUE_APP_GATEWAY_QXS}/org/safety-center/detail`, // 获取账号安全中心的信息
  getAccountScore: `${VUE_APP_GATEWAY_QXS}/org/safety-center/account-evaluation`, // 获取账号安全中心的评分
  getLoginRecord: `${VUE_APP_GATEWAY_QXS}/org/safety-center/login-info-list`, // 获取登录记录
  getSensitiveRecord: `${VUE_APP_GATEWAY_QXS}/org/safety-center/action-log-list`, // 获取敏感操作
  userMobileLogin: `${VUE_APP_GATEWAY_QXS}/public/v1/mobile/login`, // 验证码登录
  // 日报
  reportData: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/daily_report`,
  resetPasword: `${VUE_APP_GATEWAY_QXS}/factory/v1/auth/reset-pwd`, // 修改密码
  checkDaily: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/daily_report_check`, // 检查日报
  // 团队
  getTeamBaseInfo: `${VUE_APP_GATEWAY_QXS}/factory/v1/team/base-statistic`, // 厂家团队基础信息
  getTeamBaseData: `${VUE_APP_GATEWAY_QXS}/factory/v1/team/scene-statistic`, // 厂家端时间范围内团队学术推广数据统计
  createTeam: `${VUE_APP_GATEWAY_QXS}/factory/v1/team/save`, // 创建保存团队 创建支持representIds name 保存不支持仅支持teamId name
  // 部门
  getOrgList: `${VUE_APP_GATEWAY_QXS}/organization/v1/tree-list`, // 获取组织结构
  getOrgManageList: `${VUE_APP_GATEWAY_QXS}/organization/v1/org-manage-tree-list`, // 组织管理-组织架构查询（带医生医院统计信息）
  getOrgAccountList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/top-tree`, // 获取顶级组织
  getOrgAccountChildrenList: `${VUE_APP_GATEWAY_QXS}/factory/v1/sub_account`, // 获取顶级组织子级
  getDepartmentBaseInfo: `${VUE_APP_GATEWAY_QXS}/organization/v1/get-base-data`,
  getOrgTeamList: `${VUE_APP_GATEWAY_QXS}/organization/v1/teams`, // 创建团队列表
  onReNameDepartmentName: `${VUE_APP_GATEWAY_QXS}/organization/v1/rename`, // 部门重命名
  getDepartmentLayerDetail: `${VUE_APP_GATEWAY_QXS}/organization/v1/query-multi-layer-teams`, // 查看组织详情
  onDelDepartment: `${VUE_APP_GATEWAY_QXS}/organization/v1/del`, // 删除部门
  onMoveDepartment: `${VUE_APP_GATEWAY_QXS}/organization/v1/migrate`, // 迁移部门
  onEditOrgUser: `${VUE_APP_GATEWAY_QXS}/organization/v1/submit-edit-manager`, // 创建组织管理员
  onCreateDepartment: `${VUE_APP_GATEWAY_QXS}/organization/v1/add`, // 创建下级组织
  updateOrgTeam: `${VUE_APP_GATEWAY_QXS}/organization//v1/deal-team`, // 编辑部门内的团队
  // 代表列表
  getRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/v1/represents`,
  // 医生部分
  getDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/list`, // 医生列表
  getDoctorListV2: `${VUE_APP_GATEWAY_QXS}/factory/v2/doctor/list`, // 医生列表
  getDoctorListV3: `${VUE_APP_GATEWAY_QXS}/factory/v3/doctor/list`, // 医生列表
  getImportDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/doctor-import-record-list`, // 导入的医生列表
  exportImportDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/export-doctor-import-record`, // 导出导入的医生列表
  deleteImportDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/delete-import-record`, // 批量删除导入的医生列表
  importSingleDoctor: `${VUE_APP_GATEWAY_QXS}/factory/import-single-doctor`, // 导入单个医生
  batchImportDoctorInfo: `${VUE_APP_GATEWAY_QXS}/factory/batch-import-doctor-info`, // 确认批量导入数据
  batchImportOrgDoctorModelFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-org-doctor/model-file`, // 校验批量导入医生数据
  baseInfoByMobile: `${VUE_APP_GATEWAY_QXS}/factory/base-info-by-mobile`, // 医生基本信息-根据手机号查询
  addBlackList: `${VUE_APP_GATEWAY_QXS}/factory/v2/doctor/add-black-list`, // 添加进黑名单
  deleteBlackList: `${VUE_APP_GATEWAY_QXS}/factory/v2/doctor/delete-black-list`, // 解除黑名单
  platformDepartment: `${VUE_APP_GATEWAY_QXS}/common/v1/platform-departments`, // 获取所有平台科室
  jobTitles: `${VUE_APP_GATEWAY_QXS}/public/v1/person/job_titles`, // 医生职称列表
  hospitalList: `${VUE_APP_GATEWAY_QXS}/factory/v1/hospital/hospital-list`, // 厂家端医院列表
  hospitalLevelList: `${VUE_APP_GATEWAY_QXS}/public/v1/hospital/level/list`, // 医院等级
  getRepresentDoctors: `${VUE_APP_GATEWAY_QXS}/factory/v1/represent/doctors`, // 代表的用户列表
  doctorChangeRepresent: `${VUE_APP_GATEWAY_QXS}/factory/v1/represent/market-transform`, // 一键交接和市场交接
  getDoctorExam: `${VUE_APP_GATEWAY_KSH}/factory/v1/doctor/answer_exam`,
  getDoctorAuth: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/certify-info`,
  getBindRepList: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/history-bind-represent-list`, // 获取某个医生的所有历史绑定代表信息列表
  getHospitalList: `${VUE_APP_GATEWAY_QXS}/factory/v1/hospital/hospital-list`, // 医院列表
  getHospitalListDown: `${VUE_APP_GATEWAY_QXS}/factory/v1/hospital/hospital-list/down`, // 医院列表下载
  getDoctorSummary: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/overview`,
  getDoctorViewStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/doctor/durations`,
  getDoctorExamRateStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/doctor/correct_rate`,
  getDoctorGiftStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/doctor/gifts`,
  getDoctorErrorExam: `${VUE_APP_GATEWAY_KSH}/factory/v1/doctor/wrong_exams`,
  setDoctorCertifyRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/update-certify-rule`, // 设置医生认证规则
  getDoctorCertifyRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/certify-rule`, // 获取医生认证规则
  getHospitalDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/hospital/doctor/list`,
  getHospitalDoctorSummary: `${VUE_APP_GATEWAY_KSH}/factory/v1/hospital/overview`,
  getJobTitle: `${VUE_APP_GATEWAY_QXS}/public/v1/person/job_titles`,
  exportDoctorList: `${VUE_APP_GATEWAY_QXS}/`, // 导出医生列表
  exportDoctorSceneData: `${VUE_APP_GATEWAY_QXS}/`, // 导出医生场景数据 科会 调研
  getDoctorSceneData: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/doctor-scene-list`, // 获取医生场景数据 科会 调研
  getDoctorMeetingAnswerInfo: `${VUE_APP_GATEWAY_QXS}/common/v1/code/doctor-meeting-answer`, // 获取医生科会答题信息
  getDoctorLiveAnswerInfo: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor-live-detail`, // 获取医生直播答题信息
  doctorQuestionnaireDetail: `${VUE_APP_GATEWAY_QXS}/common/v1/code/doctor-answer`, // 查看医生问卷答题详情
  getDoctorAllSceneCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/doctor-code-statistic`, // 获得厂家医生各场景获得码统计和已领取积分
  updateHospitalEdit: `${VUE_APP_GATEWAY_QXS}/factory/v1/hospital/hospital-edit`, // 厂家端自定义医院编码
  getProvince: `${VUE_APP_GATEWAY_QXS}/public/v1/address/provinces`, // 获取省市信息
  getHospitalLevel: `${VUE_APP_GATEWAY_QXS}/public/v1/hospital/level/list`, // 医院等级
  uploadExcelToDoctor: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/doctor-excel-insert`, // 医生批量导入
  doctorRealNameOpt: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/submit-guarantee`, // 医生批量实名
  getDepartmentLevel: `${VUE_APP_GATEWAY_QXS}/common/v1/department-level-one`, // 获取科室列表  /common/v1/department-get 备用
  uploadDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/list-down`, // 厂家医生数据下载
  departmentQuery: `${VUE_APP_GATEWAY_QXS}/common/hospital/department/search`, // 医院科室查询
  saveDoctorCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/save-code`, // 医院科室查询
  getSpeakerListDown: `${VUE_APP_GATEWAY_QXS}/factory/v1/speaker/list-down`, // 医院科室查询
  doctorDataCheck: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/data/check`, // 医生风险校验
  doctorBatchUnbind: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/batch-unbind`, // 批量解除医生和代表的绑定关系
  doctorBatchDelete: `${VUE_APP_GATEWAY_QXS}/factory/v1/del-doctors`, // 批量解除医生列表
  doctorBatchBind: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/batch-bind`, // 批量医生和代表进行绑定
  addBlockList: `${VUE_APP_GATEWAY_QXS}/factory/v2/doctor/add-black-list`, // 医生加入黑名单
  // getFactory
  // 调研 公共部分
  getUserDoctorInfo: `${VUE_APP_GATEWAY_QXS}/common/v1/doctor/doctor-info`, // 新活动查看医生基本数据 通用接口
  getInviterCodeList: `${VUE_APP_GATEWAY_QXS}/common/v1/code-money`, // 查看码面额和有效期=>
  getQuestionnaireDraftDetail: `${VUE_APP_GATEWAY_QXS}/common/v1/questionnaire/preview`, // 获取问卷端调研信息
  getFactoryAllotDepList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/code-factory`, // 总厂家端拥有模板权限的子厂家列表
  getSceneDepList: `${VUE_APP_GATEWAY_QXS}/organization/v1/scene-factory`, // 厂家所辖部门和模板是否分配选中列表
  // 厂家端任务
  onCreateSceneRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/assign-rule-add`,
  getAllotRuleDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/code-used-by-rule`,
  factoryBuyCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/purchase-code`, // '厂家端购买任务'
  onAllotWithCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/assign-code`, // 新版买码＋分配
  onRecallSceneDepCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/recall-factory-code`, // 上级组织撤回分配给下级组织的码
  onRecallSceneRepCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/recall-code`, // 厂家端撤回代表手上任务
  getFactoryAllotCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/scene-code`, // 获取厂家端拥有任务 =>meeting or questionnaire
  getFactoryAllotUserList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/represent-list`, // 厂家端已分配代表列表
  getDoctorAllotUserList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/can-recall-code-pre-doctor-list`, // 厂家端已分配医生列表
  onFactoryConvertCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/convert-code`, // 厂家端码换积分
  getFactoryOrgUserList: `${VUE_APP_GATEWAY_QXS}/organization/v1/factory-doctor-represent-count`, // 查询一组厂家的部门人数和绑定医生数
  onEnsetRepDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/v1/represent/represent-doctor-info`, // 医生批量分配导入解析
  advertActivityList: `${VUE_APP_GATEWAY_QXS}/factory/advertActivity/v1/list`, // 活动列表
  delAdvertActivity: `${VUE_APP_GATEWAY_QXS}/factory/advertActivity/v1/del`, // 删除活动
  saveActivity: `${VUE_APP_GATEWAY_QXS}/factory/advertActivity/v1/save-or-update`, // 保存或更新活动
  addAssignActivity: `${VUE_APP_GATEWAY_QXS}/factory/v1/assign-activity/add`, // 分配任务添加关联广告活动
  onDelSceneRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/assign-rule-del`,
  getSceneRuleHistoryList: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/assign-rule-list`,

  // 公司端任务
  factoryRecallDepCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/recall-factory-code`, // 厂家撤回下级的任务
  getCanRecallAllotDepList: `${VUE_APP_GATEWAY_QXS}/factory/v1/factory/can-recall-factory-code`, // 可撤回的下级厂家任务列表
  // onCompanyConvertCode:`${VUE_APP_GATEWAY_QXS}/company/v1/code/convert-code`,//公司端转换码为积分
  // companyBuyCode:`${VUE_APP_GATEWAY_QXS}/company/v1/code/purchase-code`,//'公司端购买邀请码'
  // getCompanyAllotCode:`${VUE_APP_GATEWAY_QXS}/company/v1/code/scene-code`,//获取公司端调研拥有邀请码
  // getCompanyWillRecallFactoryList:`${VUE_APP_GATEWAY_QXS}/company/v1/factory/can-recall-factory-code`,//公司端可撤回邀请码列表
  getWithdrawRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/train/withdraw-represent-list`, // 获取可撤回项目的代表列表
  // 厂家端 账户管理
  getAccountMeetingRecord: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/account-list`, // 厂家端新科会明细
  getAccountSurveyRecord: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/account-list`, // 厂家端调研明细
  getAccountVisitRecord: `${VUE_APP_GATEWAY_KSH}/factory/v1/account/visits`, // 厂家端拜访明细
  getJsonRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/v1/represent/mobile-id`, // 获取代表身份
  getNewAccountStatistic: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/account_statistic`, // 厂家新科会账户总投入和科会账户余额
  onRecallLiveAccount: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/recall`, // 撤回下级账户直播余额,
  onRecallUserAccount: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/recall-child-gift`, // 撤回下级账户积分余额,
  // 下载管理
  onExportQuestionnaireData: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/export-download`, // 调研数据文件下载
  getDownloadFileList: `${VUE_APP_GATEWAY_QXS}/factory/v1/download-center/list`,
  exportMeetingData: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/export-download`, // 导出科会数据
  examListDown: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/exam-list/down`, // 导出题目/问卷数据
  // 权限
  getAccounts: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/users`, // 权限列表
  deleteUser: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/remove_user`,
  editUser: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/create_or_update/user`,
  getAccountsRoles: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/roles`,
  roleList: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/role-list`,
  getDepartmentRecord: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/gift-transfer-records`, // 部门转账记录
  // 科会接口
  // 科会分配
  getMeetingAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/quota_detail`,
  updateMeetingAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/add_quota`,
  createMeetingAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/save_quota`,
  getSceneAllotRecordList: `${VUE_APP_GATEWAY_QXS}/org/project/v1/code-assign-record-list`, // 各场景详情页任务分配记录列表
  onDownSceneAllotRecordData: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/assign-detail-download`, // 买码分配记录详情下载
  // 文献接口操作
  createArticle: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledge/create`,
  updateArticle: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledge/update`,
  deleteArticle: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledge/delete`,
  getArticleDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledge/show`,
  getArticleList: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledges`,
  createPDF: `${VUE_APP_GATEWAY_QXS}/factory/v1/knowledge/create_pdf`, // 创建PDF文献
  // 视频接口
  getUploadVideoInfo: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/get_create_video`,
  refreshUploadVideo: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/refresh_upload_video`, // 刷新阿里云上传视频
  getVideoList: `${VUE_APP_GATEWAY_QXS}/factory/v1/videos`,
  getVideoDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/show`,
  deleteVideo: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/delete`,
  updateVideo: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/update`,
  checkSimilarVideo: `${VUE_APP_GATEWAY_QXS}/factory/v1/video/get-similar-info`,
  // 问卷接口
  createExam: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/create`,
  updateExam: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/update`,
  deleteExam: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/delete`,
  getExamList: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/exam-list`,
  getExamDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/show`,
  getVisitExamDetail: `${VUE_APP_GATEWAY_KSH}/public/v1/visit/doctor_exam_detail`,
  // 文件上传 Excel
  uploadFile: `${VUE_APP_GATEWAY_KSH}/factory/v1`,
  // 信息列表
  getMessageList: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/feedbacks`,
  getApplyList: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/applies`,
  rejectMeetingAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/reject`,
  getApplyDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/apply_detail`,
  approveAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/approve`,
  getPlatformList: `${VUE_APP_GATEWAY_QXS}/factory/v1/message/platform_message`,
  onReadPlatform: `${VUE_APP_GATEWAY_QXS}/factory/v1/message/platform_message_read`,
  getTransformMessage: `${VUE_APP_GATEWAY_QXS}/factory/v1/message/transform_details`,
  getAllotMessage: `${VUE_APP_GATEWAY_KSH}/factory/v1/message/distribution_details`,
  // 创建分类
  createCategory: `${VUE_APP_GATEWAY_QXS}/factory/v1/category/create`, // 题目添加分类
  deleteCategory: `${VUE_APP_GATEWAY_QXS}/factory/v1/category/delete`,
  categoryUpdate: `${VUE_APP_GATEWAY_QXS}/factory/v1/category/update`, // 编辑分类
  getCategoryList: `${VUE_APP_GATEWAY_QXS}/factory/v1/categories`,
  updateExamCategory: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/update-exam-categories`, // 修改单题问题分类
  // 统计
  getMeetingDetailStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/statistic`, // 科室会团队数据
  getMeetingTeamStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/team_statistic`, // 科室会统计数据
  getTeamRepresentStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/rep_statistic`, // 团队代表统计数据
  getSceneCommentList: `${VUE_APP_GATEWAY_QXS}/factory/comment/v1/first-level-comment-list`, // 获取场景评论
  onShieldComment: `${VUE_APP_GATEWAY_QXS}/factory/comment/v1/shield-comment`,
  onSetTopComment: `${VUE_APP_GATEWAY_QXS}/factory/comment/v1/top-comment`,
  onSetActiveComment: `${VUE_APP_GATEWAY_QXS}/factory/comment/v1/selective-comment`,
  setCommentShowType: `${VUE_APP_GATEWAY_QXS}/factory/comment/v1/setting/save-or-update`,
  getSceneReplyList: `${VUE_APP_GATEWAY_QXS}/common/comment/v1/reply/list`,
  onReplyComment: `${VUE_APP_GATEWAY_QXS}/common/comment/v1/reply`,
  getSceneTemplateList: `${VUE_APP_GATEWAY_QXS}/factory/scene-template/get-scene-template-list`,
  getMeetingGiftStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/gift_statistic`, // 科会学分数据
  // 显示权限
  getPermission: `${VUE_APP_GATEWAY_QXS}/factory/permission/v1/views`,
  getDoctorImportRealAuthCount: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/month-guarantee-count`,
  // 数据管理
  getDataManageOverview: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/overview`,
  checkUserMonthData: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_month_data`,
  getDoctorMonthData: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_monthly`,
  getDoctorStudyTime: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/view_time_distribution`,
  getDoctorJobList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/job_distribution`,
  getDataManageMeetingList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/meetings`,
  getMeetingBaseData: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/meeting_basedata`,
  getMeetingExamData: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/meeting_exams`,
  getDataManageHospitalList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/hospital_list`,
  getDataManageDoctorList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_list`,
  getDataManageDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_overview`,
  getDataManageTeamList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_list`,
  getDataManageTeamInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_overview`,
  getTeamRepresentList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_represents`,
  getDataManageRepresentInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/represent_overview`,
  getRepresentCustormerList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_represent_doctors`,
  getRepresentUnAuthDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/represent_unauth_doctors`,
  getDoctorCompared: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_comparison`,
  getHospitalCompared: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/hospital_comparison`,
  getMeetingCompared: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/meeting_compare`,
  getRepresentCompared: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_represent_comparison`,
  getTeamCompared: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_comparison`,
  getTeamRepresentSelectList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/comparison_represents`,
  getComparisonHospitalList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/comparison_hospitals`,
  getComparisonDoctorList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/comparison_doctors`,
  getDataMeetingList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/meeting_list`,
  getDoctorMeetingInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_meetings`,
  getMeetingAllotTeam: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/team_quota`,
  getMeetingAllotRecords: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/quota_records`,
  getMeetingAllotDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/quota_detail`,
  getRepresentErrorExamList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/represent_wrong_exams`,
  getDoctorErrorExamList: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/doctor_wrong_exams`,
  getExamDataManage: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/library_exams`,
  getExamLookInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/library_exam_detail`,
  getArticleDataManage: `${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/library_knowledges`,
  getMeetingRecordList: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/records`, // 科会积分获取情况
  getMeetingDcotorRecordList: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/doctor_gift_records`,
  getMeetingExamReport: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/answer_report`,
  getMeetingRepresentList: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/all_represent`, // 获取科会下所有代表
  getDoctorInviteList: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/doctor_meeting_report`, // 使用任务观看医生列表
  getMeetingSimpleInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/meeting/meeting_detail`,
  // 调研详情。列表
  getSurveyDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/detail`,
  getSurveyList: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/list`,
  createSurvey: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/create`,
  updateSurvey: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/update`,
  // 调研撤回
  recallSurvey: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/recall`,
  // 调研分配
  getSurveyAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/quota_detail`,
  updateSurveyAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/add_quota`,
  createSurveyAllot: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/save_quota`,
  // 调研发布
  surveyPublish: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/publish`,
  getSurveyStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/detail`,
  getSurveyExamList: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/answer_report`,
  getSurveyExamDetail: `${VUE_APP_GATEWAY_KSH}/doctor/v1/survey/questionnaire`,
  getSurveyUserList: `${VUE_APP_GATEWAY_KSH}/factory/v1/survey/doctor_list`,
  // 拜访
  // saveVisit: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/save`,
  // getVisitList: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/list`,
  // publishVisit: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/publish`,
  // getVisitDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/detail`,
  // onRecallVisit: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/recall`,
  // getVisitAllotDetail: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/quota_detail`,
  // getVisitRepresentStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/represent/invited_list`,
  // getVisitStat: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/statistical`,
  // getVisitExamList: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/exams`,
  // onExportVisit: `${VUE_APP_GATEWAY_KSH}/factory/v1/visit/statistical_export`,
  // 市场部学术资源
  // 市场部账户管理
  getFactoryList: `${VUE_APP_GATEWAY_KSH}/company/v1/account/factory-account`, // 获取子厂家列表
  getCompanyViewKey: `${VUE_APP_GATEWAY_KSH}/company/v1/factory/factory-data`, // 市场部获取查看厂家权限
  // 市场部权限管理
  // 数据大屏
  screenBaseInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/base-info`, // 数据大屏--基础数据
  screenActiveInfo: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/active-info`, // 数据大屏--厂家活跃数据
  screenTeamData: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/team-code-statistic`, // 数据大屏--团队kpi
  screenRepresentData: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/represent-code-statistic`, // 数据大屏--代表kpi
  getProvinceMap: `${VUE_APP_GATEWAY_KSH}/public/v1/address/province-show`,
  getScreenAreaDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/doctor-active`, // 数据大屏--医生活跃区间分布
  getScreenProvinceDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/doctor-active-province`, // 数据大屏--医生活跃分布--省
  getScreenHospitalLevel: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/hospital-statistic`, // 数据大屏--医院等级医生分布
  getScreenDepartmentLevel: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/department-statistic`, // 数据大屏--科室部门医生分布
  // getScreenJobLevel:`${VUE_APP_GATEWAY_KSH}/factory/v1/statistic/job_distribution`,//数据大屏--职称等级医生分布
  getScreenActiveDoctor: `${VUE_APP_GATEWAY_KSH}/factory/v1/data/active-statistic`, // 数据大屏--活跃医生变化趋势=
  // 厂家调研
  createQuestionnaire: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/create`, // 创建厂家端调研问卷
  updateQuestionnaire: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/edit`, // 编辑厂家端调研问卷
  updateQuestionnaireNormal: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/edit-attribute`, // 编辑厂家端调研问卷
  delFactoryQuestionnaireDraft: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/del`, // 删除厂家端调研问卷
  publishFactoryQuestionnaireDraft: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/publish`, // 发布厂家端调研
  getFactoryQuestionnaireDraftList: `${VUE_APP_GATEWAY_QXS}/factory/v1/questionnaire/list`, // 获取厂家调研模板列表
  getFactoryQuestionnaireCodeList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/questionnaire-list`, // 厂家端待分配的调研资源列表
  // 厂家拜访
  academicVisitCreate: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/create`,
  academicVisitEdit: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/edit`,
  academicVisitEditNormal: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/edit-attribute`,
  getAcademicVisitDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/view`,
  getDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/view-represent`,
  delAcademicVisit: `${VUE_APP_GATEWAY_QXS}/factory/v1/visit/del`,
  getDepartmentSceneData: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/factory-statistic`, // 公司端单个场景厂家推广数据
  // 厂家端新科会
  getAcademicSceneList: `${VUE_APP_GATEWAY_QXS}/org/project/v1/list`, // 厂家端待分配的场景资源邀请列表
  podcastListDown: `${VUE_APP_GATEWAY_QXS}/factory/medical-podcast/v1/podcast-submission/multi/list/down`, // 播客 作品内容列表下载
  getDownOrgList: `${VUE_APP_GATEWAY_QXS}/org/project/junior/list`, // 查询下级组织项目
  getFactoryMeetingDraftList: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/list`, // 获取厂家新科会列表
  onDelMeeting: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/del`, // 删除厂家科会
  publishFactoryMeetingDraft: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/publish`, // 发布科会
  createMeeting: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/create`, // 创建科会
  updateMeeting: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/edit`, // 编辑科会
  updateMeetingNormal: `${VUE_APP_GATEWAY_QXS}/factory/v1/meeting/edit-attribute`,
  getFactorySceneTeamData: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/team-statistic`, // 厂家单个场景团队执行数据
  getFactoryMeetingRepresentData: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/represent-statistic`, // 厂家单个场景代表执行数据
  getFactoryMeetingDoctorData: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/doctor-statistic`, // 厂家端场景下医生推广数据 meeting or questionnaire
  getMeetingDraftView: `${VUE_APP_GATEWAY_QXS}/common/v1/meeting/preview`, // 获取科会模板信息
  // 跳题规则
  onCreateSceneExamJumpRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/add-jump-exam-logic`, // 新增跳题逻辑
  onEditSceneExamJumpRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/edit-jump-exam-logic-list`, // 新增跳题逻辑
  getSceneExamJumpRuleList: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/jump-exam-logic-list`,
  onDelSceneExamJumpRule: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/del-jump-exam-logic`,
  onValidExamJumpLogic: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/has-set-jump-exam-logic`, // 查询单个题目是否已设置过跳题逻辑
  getSimilarExamList: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/similar-exams`,
  getMaterialExamList: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/exam-list-with-answers`,
  getQuestionnaireJumpTree: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/jump-exam-logic-overview`, // 查看整体跳题逻辑
  // 厂家端直播场景
  getLiveDraftList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/model-list`, // 获取厂家新直播列表
  getLiveSummary: `${VUE_APP_GATEWAY_QXS}/org/live/v1/get-live-detail-data-overview`, // 获取直播/多人会议详情数据概况
  getLiveDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/edit`, // 获取直播模板信息
  createLive: `${VUE_APP_GATEWAY_QXS}/org/live/v1/save`, // 创建编辑直播
  checkLiveSpeaksRule: `${VUE_APP_GATEWAY_QXS}/org/live/v1/checkLiveSpeaksRule`, // 检查直播场次超标
  onDelLive: `${VUE_APP_GATEWAY_QXS}/org/live/v1/del`, // 删除直播
  publishLiveDraft: `${VUE_APP_GATEWAY_QXS}/`, // 发布直播
  setProjectBindDepartment: `${VUE_APP_GATEWAY_QXS}/org/live/v1/bind-factory`, // 厂家科会模板分配给子厂家
  getLivePreviewQr: `${VUE_APP_GATEWAY_QXS}/org/live/v1/get-qrcode`, // 获取直播预览二维码
  getSpeakerList: `${VUE_APP_GATEWAY_QXS}/factory/v1/speaker/list`, // 获取播客列表
  addPodcastUser: `${VUE_APP_GATEWAY_QXS}/factory/podcast/v1/add_podcast_user`, // 添加播客讲者
  getRepresentOrgList: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor/represent/org-list`, // 查询医生和代表的绑定的组织列表
  getDepartmentList: `${VUE_APP_GATEWAY_QXS}/common/v1/hospital-departments`, // 获取科室数据
  commonV1HospitalList: `${VUE_APP_GATEWAY_QXS}/common/v1/hospital-list`, // 获取医院数据
  getProvinceList: `${VUE_APP_GATEWAY_QXS}/common/v1/provinces`, // 获取省数据
  getLiveCodeList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/live-list`, // 直播资源列表
  getLiveConsoleDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/control-center/detail`, // 直播控制台详情
  getIMUserInfo: `${VUE_APP_GATEWAY_QXS}/common/v1/get-im-info`, // 请求换取userSig
  replayLive: `${VUE_APP_GATEWAY_QXS}/org/live/v1/replay-status-change`, // 回放直播
  getBlackLiveList: `${VUE_APP_GATEWAY_QXS}/common/live/v1/black-list`, // 直播的黑名单
  handleBlackList: `${VUE_APP_GATEWAY_QXS}/common/live/v1/black-list-deal`, // 移出加入直播的黑名单
  getLivePacketList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/query-red-packet-detail`, // 直播的领取红包记录
  exportRedDetailList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/export/red-package-detail-list`, // 导出领取红包记录
  submitRedEnvelope: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/create-red-packet`, // 发积分红包
  getRedPacketExamDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/get-red-packet-exam-detail`, // 获取红包答题详情
  exportRedPacketExamDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/console/red-packet/doctor-answer/export`, // 导出某个答题红包对应的医生答题信息列表
  getLiveOverview: `${VUE_APP_GATEWAY_QXS}/org/live/v1/statistic-data`, // 获取直播数据信息
  inviteDoctorCertify: `${VUE_APP_GATEWAY_QXS}/factory/v1/invite/doctor-auth`, // 播客账户邀请认证医生
  signUpDoctor: `${VUE_APP_GATEWAY_QXS}/factory/v1/speaker/sign-up`, // 播客账户签约播客
  unSignUpDoctor: `${VUE_APP_GATEWAY_QXS}/factory/v1/speaker/remove`, // 播客账户解绑播客
  getDynamicData: `${VUE_APP_GATEWAY_QXS}/common/live/v1/dynamic-data`, // 直播动态数据
  getLiveOldMessageList: `${VUE_APP_GATEWAY_QXS}/common/live/v1/msg-list`, // 回放直播的老消息列表
  dealChatMsg: `${VUE_APP_GATEWAY_QXS}/common/live/v1/deal-chat-msg`, // 处理直播间消息
  editViewCount: `${VUE_APP_GATEWAY_QXS}/org/live/v1/bc-change`, // 修改在线基数
  getSpeakerOverview: `${VUE_APP_GATEWAY_QXS}/factory/v1/speaker/overview`, // 播客管理账户概览数据
  getImEnvelope: `${VUE_APP_GATEWAY_QXS}/common/live/v1/get-red-packet`, // 聊天室红包信息
  getRedEnvelopeDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/get-red-packet-detail`, // 聊天室红包详情
  getLiveRedPacketList: `${VUE_APP_GATEWAY_QXS}/org/live//v1/get-live-red-packet-list`, // 中控台获取红包列表
  recallIMRedEnvelope: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/roll-back-red-packet`, // 撤回聊天室红包
  handleLiveStatus: `${VUE_APP_GATEWAY_QXS}/org/live/v1/change-stream-status`, // 直播开始和结束
  getLiveCacheList: `${VUE_APP_GATEWAY_QXS}/common/v1/cache-videos`, // 回放视频列表
  onDelVideoCache: `${VUE_APP_GATEWAY_QXS}/common/v1/del-tx-videos`, // 删除回放视频
  onMergeVideo: `${VUE_APP_GATEWAY_QXS}/common/v1/edit-tx-videos`, // 编辑视频
  onSettingReplayVideo: `${VUE_APP_GATEWAY_QXS}/common/v1/replay-video-set`, // 设置为回放视频
  getDoctorLiveList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/live-video-list`, // 播客详情页 知识店铺 直播课程
  recordLiveTime: `${VUE_APP_GATEWAY_QXS}/common/live/v1/record-time`, // 记录观看时长
  clientExe: `${VUE_APP_GATEWAY_QXS}/public/v1/push-client/latest-version`, // 播客端的下载地址
  getGiftList: `${VUE_APP_GATEWAY_QXS}/common/live/v1/live-gift-list`, // 礼物列表
  checkItemGift: `${VUE_APP_GATEWAY_QXS}/common/live/v1/live-gift`, // id查询单个礼物
  // help
  getHelpNavList: `${VUE_APP_GATEWAY_QXS}/common/article/v1/category-list`, // 帮助中心导航拦
  helpMessageList: `${VUE_APP_GATEWAY_QXS}/common/article/v1/list`, // 帮助中心列表
  helpMessageDetail: `${VUE_APP_GATEWAY_QXS}/common/article/v1/detail`, // 帮助中心文章详情'
  helpMessageMark: `${VUE_APP_GATEWAY_QXS}/common/article/v1/mark`, // 标记喜欢或没用
  platformFeedback: `${VUE_APP_GATEWAY_QXS}/common/platform-feedback/v1/add`, // 平台反馈消息
  // common
  getAttributeDoctorFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-attribute-doctor/model-file`,
  getAttributeRepresentFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-attribute-represent/model-file`,
  uploadImage: `${VUE_APP_GATEWAY_QXS}/common/v1/upload-image`, // 图片上传通用接口 104 封面图，110 身份证， 111 银行卡	116 问题反馈
  getUserGuide: `${VUE_APP_GATEWAY_QXS}/common/v1/guide`, // 用户新手指导
  getTagGroup: `${VUE_APP_GATEWAY_QXS}/common/v1/tag/query-tag-group`, // 标签分组
  getTagList: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/tag-query`, // 标签列表
  tagList: `${VUE_APP_GATEWAY_QXS}/factory/my-collect/tag-list`, // 获取我选择使用的标签库
  createTag: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/tag-add`, // 创建标签
  editTag: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/tag-name-update`, // 编辑标签
  deleteTag: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/tag-del`, // 删除标签
  onBindSceneTag: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/tag-bind`, // 绑定标签
  getProjectTag: `${VUE_APP_GATEWAY_QXS}/common/v1/tag/query-tag-list`, // 已经绑定的科会
  getTagCoverDoctorSummary: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor-level/count`, // 厂家端查看产品线已分级未分级医生数
  getSmsCode: `${VUE_APP_GATEWAY_QXS}/public/sms-code/v1/send`, // 获取验证吗
  sendMessage: `${VUE_APP_GATEWAY_QXS}/public/sms-code/v1/send-message`, // 获取验证码，防止短信轰炸
  getFactoryAccountList: `${VUE_APP_GATEWAY_QXS}/public/org-person/v1/user-list`, // 获取切换的厂家账号列表
  setNormalAccount: `${VUE_APP_GATEWAY_QXS}/public/org-person/v1/login-user`, // 设置默认账号
  getTagCoverDoctorList: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor-level/questionnaire-list`, // 已分级、待分级客户问卷和客户信息列表
  setGradeDoctor: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor-level/questionnaire-audit`, // 分级问卷审核、评级
  getTagCoverDoctorChartData: `${VUE_APP_GATEWAY_QXS}/factory/v1/doctor-level/questionnaire-statistic-table`,
  bindMobile: `${VUE_APP_GATEWAY_QXS}/common/v1/mobile/bind`, // 绑定手机号
  changeMobile: `${VUE_APP_GATEWAY_QXS}/common/v1/mobile/change`, // 更换手机号
  changePwd: `${VUE_APP_GATEWAY_QXS}/factory/v1/auth/modify-password`, // 修改密码（验证老密码）
  setNewPassword: `${VUE_APP_GATEWAY_QXS}/factory/v1/login/new-password`, // 设置新密码（忘记密码、验证手机号修改密码）
  paymentCheckValue: `${VUE_APP_GATEWAY_QXS}/org/safety-center/get-check-value`, // 支付限额校验验证码并获取凭证
  paymentLimit: `${VUE_APP_GATEWAY_QXS}/org/safety-center/modify-pay-limit`, // 修改厂家支付限额
  checkCaptcha: `${VUE_APP_GATEWAY_QXS}/public/captcha/v1/check`, // 检查人机校验
  bindWechat: `${VUE_APP_GATEWAY_QXS}/factory/v1/bind-wx`, // 绑定或换绑微信
  getBannerList: `${VUE_APP_GATEWAY_QXS}/common/v1/index/wheel-list`,
  // account
  getAccountLiveSummary: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/overview`, // 账户直播数据汇总
  getAccountUserSummary: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/score-account-detail`, // 账户用户数据汇总
  getAccountUserList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/collect-list`, // 账户用户数据列表
  getAccountUserChildrenList: `${VUE_APP_GATEWAY_QXS}/factory/v1/sub-account/collect-list`, // 账户用户数据列表子级
  downloadAccountCollectList: `${VUE_APP_GATEWAY_QXS}/factory/v1/download/account/collect-list`, // 账户用户数据列表下载
  getOrgAccountAmount: `${VUE_APP_GATEWAY_QXS}/factory/v1/batch-query-account`, // 获取组织当前账户余额
  onDownAccountData: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/detail-list/export`, // 导出账户数据
  downloadAllDetailList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/all-detail-list/export`, // 账户用户数据列表下载
  downloadDelegationAllCollectList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/all-detail-list/export-delegation`, // 导出我及我以下组织委托服务财务明细列表
  onTransferAccountUser: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/transfer`, // 给帐号转账积分
  onTransferAccountLive: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/transfer`, // 给直播服务转账积分
  onExportAccountLivePayData: `${VUE_APP_GATEWAY_QXS}/org/live/v1/speaker-pay-list/export`, // 导出直播支付详情数据
  onPayLiveAccount: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/pay-fee`, // 播客管理账户--支付视频采购费
  getAccountLivePayDetail: `${VUE_APP_GATEWAY_QXS}/org/live/v1/speaker-pay-list`, // 播客账户 查看直播讲课
  getAccountBusinessList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/detail`,
  onReviewLiveAccount: `${VUE_APP_GATEWAY_QXS}/org/live/v1/submit-review`, // 直播账户提交审核
  getLiveBusinessListDown: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/live-service-fee/detail-list-download`, // 视频采购费-业务明细 导出
  getAccountFactorySetting: `${VUE_APP_GATEWAY_QXS}/factory/v1/query-factory-setting`, // 查询资金管理权限设置
  updateAccountFactorySetting: `${VUE_APP_GATEWAY_QXS}/factory/v1/factory-setting`, // 查询资金管理权限设置
  getAccountLiveBusinessList: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/live-service-fee/detail-list`, // 视频采购费-业务明细
  // account detail
  scoreAccountDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/scene-detail`, // 进行中的任务，页头总统计数据
  accountCodeList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/code-list`, // 进行中的任务，table数据
  callBackCodeDetail: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/call-back-code-detail`, // 撤回任务 统计
  callBackCodeList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/call-back-scene-list`, // 撤回任务，table数据
  sceneCodeOverview: `${VUE_APP_GATEWAY_QXS}/org/project/v1/scene-code-overview`, // 撤回任务，任务面额list
  recallCodeFactory: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/recall-factory-code`, // 撤回任务提交 -- 厂家Tab
  cashDetailList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/detail-list`, // 学分账户明细列表
  commonConvertCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/common-convert-code`, // 撤回邀请码提交 ---- 代表、厂家Tab
  trainWithdrawCode: `${VUE_APP_GATEWAY_QXS}/factory/train/withdraw-code`, // 撤回代表培训积分
  getTrainCode: `${VUE_APP_GATEWAY_QXS}/factory/train/on-going-code-top`, // 获取正在进行中的带积分的任务
  getTrainDetail: `${VUE_APP_GATEWAY_QXS}/factory/train/on-going-code-detail`, // 获取正在进行中的积分列表
  getWithdrawList: `${VUE_APP_GATEWAY_QXS}/factory/train/withdraw-program-list`, // 查询可撤回项目列表
  getWithdrawMoney: `${VUE_APP_GATEWAY_QXS}/factory/train/can-withdraw-money`, // 获取代表培训可撤回的总积分数
  recallResource: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/represent-factory-rule-scene-info`, // 代表-单个厂家-单个项目-单个规则下-可撤回资源信息
  recallRepresentCode: `${VUE_APP_GATEWAY_QXS}/factory/recall/represent-code`, // 单个撤回项目
  rejectPayFee: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/reject-pay-fee`, // 拒绝支付讲课费
  // 课程培训
  sendPreview: `${VUE_APP_GATEWAY_QXS}/factory/train/push-view-represent`, // 厂家推送代表预览
  // ---------------- 课程培训 --------------------------
  trainClassify: `${VUE_APP_GATEWAY_QXS}/factory/train/classify`, // 获取代表培训内容分类
  creatTrainClassify: `${VUE_APP_GATEWAY_QXS}/factory/train/create-classify`, // 创建培训课程分类
  removeTrainClassify: `${VUE_APP_GATEWAY_QXS}/factory/train/remove-classify`, // 创建培训课程分类
  trainCourseList: `${VUE_APP_GATEWAY_QXS}/factory/train/course-list`, //  获取厂家端课程列表
  trainModifyCourse: `${VUE_APP_GATEWAY_QXS}/factory/train/modify-course`, // 保存课程详情
  trainCoursePreview: `${VUE_APP_GATEWAY_QXS}/common/train/course-preview`, // 查看课程预览
  trainVideoList: `${VUE_APP_GATEWAY_QXS}/common/train/video-list`, // 通过课程编号获取视频列表
  trainExamList: `${VUE_APP_GATEWAY_QXS}/common/train/exam-list`, // 通过课程编号查询题目列表
  trainCourseDistribute: `${VUE_APP_GATEWAY_QXS}/factory/train/course-distribute`, // 课程分配给下级
  trainRemoveCourse: `${VUE_APP_GATEWAY_QXS}/factory/train/remove-course`, //  删除课程
  trainProgramList: `${VUE_APP_GATEWAY_QXS}/factory/train/program-list`, // 获取项目列表
  trainModifyProgram: `${VUE_APP_GATEWAY_QXS}/factory/train/modify-program`, // 创建/修改项目
  trainProgramDataInfo: `${VUE_APP_GATEWAY_QXS}/factory/train/program/data-info`, // 项目数据（项目详情+课程详情）
  trainRemoveProgram: `${VUE_APP_GATEWAY_QXS}/factory/train/remove-program`, // 删除培训项目（未分配的项目可被删除）
  teamTrainRepresents: `${VUE_APP_GATEWAY_QXS}/factory/v1/team/train-represents`, // 团队培训代表列表
  screeningRepresents: `${VUE_APP_GATEWAY_QXS}/factory/screening/represent`, // 筛选组织下的代表列表（分配时候用）
  trainPurchaseTrainCode: `${VUE_APP_GATEWAY_QXS}/factory/train/purchase-train-code`, // 添加代表进行培训（隐性买码分配流程）
  trainProgramProcessData: `${VUE_APP_GATEWAY_QXS}/factory/train/program/process-data`, // 项目培训数据统计
  trainExportProcessData: `${VUE_APP_GATEWAY_QXS}/factory/train/export/process-data`, // 导出项目培训数据
  videosPlayUrl: `${VUE_APP_GATEWAY_QXS}/factory/v1/videos/play-url`, // 获取单个视频播放地址
  programTotalData: `${VUE_APP_GATEWAY_QXS}/factory/train/program/total-data`, // 获取厂家端-项目信息total
  getCourseDetail: `${VUE_APP_GATEWAY_QXS}/factory/train/course-detail`, // 课程详情
  contactUs: `${VUE_APP_GATEWAY_QXS}/factory/train/contact-us`, // 客户对课程有兴趣（联系我们）
  getCodeUsedStatistic: `${VUE_APP_GATEWAY_QXS}/org/project/v1/code-used-statistic`, // 各场景详情页任务总数及其对应的积分总数
  getSceneCodeUsedStatistic: `${VUE_APP_GATEWAY_QXS}/org/project/v1/org-scene-code-used-statistic`, // 各场景详情页任务总数及其对应的积分总数
  trainCourseDegreeDistribute: `${VUE_APP_GATEWAY_QXS}/factory/train/course-degree-distribute`, // 课程次数分配给下级厂家
  getChildFactoryList: `${VUE_APP_GATEWAY_QXS}/organization/v1/list-child-factory`, // 课程次数分配给下级厂家
  checkCourseDegree: `${VUE_APP_GATEWAY_QXS}/common/train/check-course-degree`, // 检查剩余的课程数是否支持分配
  getTrainRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/train/all-represent`, // 检查剩余的课程数是否支持分配
  // ---------------- 课程培训 end ----------------------
  // ---------------- 财务管理 start --------------------
  getVideoUrl: `${VUE_APP_GATEWAY_QXS}/public/v1/video/show`,
  getSourceList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/source-list`, // 查询积分摘要列表
  getSceneList: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/scene-list`, // 场景列表
  getDetailDown: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/detail-down`, // 厂家业务明细下载
  getIsOrgSetAdmin: `${VUE_APP_GATEWAY_QXS}/org/cash/v1/is_org_set_admin`, // 校验org是否设置了管理员
  getWebinarSpeakerList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/get-speaker-info`,
  getLiveRecordList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor-view-records`,
  downLoadLiveRecordList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/download/doctor-view-records`,
  importWhiteList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/import-white-list`, // 导入直播白名单
  getLiveWhiteList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/get-live-white-list`, // 分页获取直播白名单
  removeLiveWhiteList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/remove-live-white-list`, // 删除白名单
  codeListExport: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/code-list-export`,
  integrationAccountDownload: `${VUE_APP_GATEWAY_QXS}/organization/v1/integration_account_download`, // 财务管理-积分账户-转账（可支配余额导出）
  // ---------------- 财务管理 end --------------------
  // ------------------------  组织管理 start ----------------------------
  getOrgSearchList: `${VUE_APP_GATEWAY_QXS}/organization/v1/search`, // 搜索员工、组织
  getOrgOperate: `${VUE_APP_GATEWAY_QXS}/organization/v1/get-operate`, // 获取单个组织的操作权限（是否可以编辑、删除、迁移）
  editOrgInfo: `${VUE_APP_GATEWAY_QXS}/organization/v1/edit`, // 编辑部门信息
  getOrgInfoList: `${VUE_APP_GATEWAY_QXS}/organization/v1/get-org-info`, // 获取部门信息
  frozenOrgInfo: `${VUE_APP_GATEWAY_QXS}/organization/v1/frozen`, // 冻结部门
  unFrozenOrgInfo: `${VUE_APP_GATEWAY_QXS}/organization/v1/un-frozen`, // 接冻部门
  uploadLogo: `${VUE_APP_GATEWAY_QXS}/organization/v1/upload-logo`, // 上传logo
  representSceneList: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/represent-scene-list`, // 代表任务资源列表
  factoryUsePer: `${VUE_APP_GATEWAY_QXS}/factory/data/v1/factory-use-per`, // 资源使用排名、部门团队、个人
  createOrModify: `${VUE_APP_GATEWAY_QXS}/factory/permission/user/create-or-modify`, // 新增、编辑管理员
  removeUser: `${VUE_APP_GATEWAY_QXS}/factory/permission/user/remove`, // 删除管理员信息
  orgStaffList: `${VUE_APP_GATEWAY_QXS}/factory/represent/list`, // 组织管理-员工列表
  freezeStaff: `${VUE_APP_GATEWAY_QXS}/factory/freeze/represent`, // 组织管理-单个冻结/解冻代表
  freezeMoreStaff: `${VUE_APP_GATEWAY_QXS}/factory/batch/freeze/represent`, // 组织管理-批量冻结/解冻代表
  projectJuniorList: `${VUE_APP_GATEWAY_QXS}/org/project/junior/list`, // 查询下级组织项目
  bindRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/bind-represents`, // 厂家下的代表列表
  batchRecallCode: `${VUE_APP_GATEWAY_QXS}/factory/v1/code/batch-recall-code`, // 撤回厂家下的代表资源
  factoryDirectRepresent: `${VUE_APP_GATEWAY_QXS}/factory/direct-represent`, // 组织下直属代表
  factoryAllRepresent: `${VUE_APP_GATEWAY_QXS}/factory/all-represent`, // 组织下所有在职员工
  needNoticeDelRepresent: `${VUE_APP_GATEWAY_QXS}/factory/is-need-notice-del-represent`, // 查询是否需要提示删除代表弹窗
  delOrgRepresent: `${VUE_APP_GATEWAY_QXS}/factory/del/org-represent`, // 删除当前层级组织下的代表绑定关系
  getCurrentLoginInfo: `${VUE_APP_GATEWAY_QXS}/factory/get-login-info`, // 获取当前登录信息
  getOrganizationSiblingList: `${VUE_APP_GATEWAY_QXS}/organization/sibling-list`, // 同级组织列表
  batchImportOrgModel: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-import-org/model-file`, // 获取批量导入组织模板下载地址
  uploadBatchImportOrg: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-org`, // 上传组织数据
  uploadBatchImportOrgConfirm: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/batch-import-org`, // 上传组织数据
  batchImportRepresentModel: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-import-represent/model-file`, // 获取下载批量导入员工列表
  uploadBatchImportRepresent: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-represent`, // 获取下载批量导入员工列表
  uploadBatchImportRepresentConfirm: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/batch-import-represent`, // 获取下载批量导入员工列表
  getRepresentDetailInfo: `${VUE_APP_GATEWAY_QXS}/factory/search/represent-info`, // 获得员工详情
  representsDownload: `${VUE_APP_GATEWAY_QXS}/factory/v1/represents/download`, // 员工列表下载
  // screenRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/screening/represent`, // 分配选择代表列表
  screenTrainRepresentList: `${VUE_APP_GATEWAY_QXS}/factory/screening/train-represent`, // 分配代表培训 代表列表
  createUpdateRepresent: `${VUE_APP_GATEWAY_QXS}/factory/v1/create-update/represent`, // 新建/编辑员工
  conditionTreeList: `${VUE_APP_GATEWAY_QXS}/organization/v1/condition/tree-list`, // 条件过滤组织树
  getRepresentInfo: `${VUE_APP_GATEWAY_QXS}/factory/search/represent-org`, // 根据代表手机号查询代表绑定的组织，当前登录的厂家及以下绑定的
  changeOrganization: `${VUE_APP_GATEWAY_QXS}/factory/change-organization`, // 变更代表归属组织
  getBatchConditionTreeList: `${VUE_APP_GATEWAY_QXS}/organization/v1/batch-condition/tree-list`, //
  downloadOrgList: `${VUE_APP_GATEWAY_QXS}/organization/v1/org-manage-tree-list-download`, // 组织管理-组织架构下载（带医生医院统计信息）
  // 项目管理 批量上传
  uploadBatchAttributeRepresent: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-attribute-represent/model-file`, // 项目管理 批量上传代表
  uploadBatchAttributeDoctor: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-attribute-doctor/model-file`, // 项目管理 批量上传医生
  getExcelResultData: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/excel-result-data`, // 项目管理 批量上传医生
  fileUpload: `${VUE_APP_GATEWAY_QXS}/common/v1/file/upload`, // 上传文件
  immFormat: `${VUE_APP_GATEWAY_QXS}/common/v1/file/imm-format`, // ppt转pdf
  trashList: `${VUE_APP_GATEWAY_QXS}/org/trash/list`, // 查询回收数据列表
  trashRecall: `${VUE_APP_GATEWAY_QXS}/org/trash/recall`, // 回收数据
  queryType: `${VUE_APP_GATEWAY_QXS}/org/trash/query-type`, // 查询回收站数据类型
  knowledgePointTreeNode: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/tree-node`, // 查询关键信息数据
  knowledgePoint: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/save`, // 保存关键信息
  knowledgePointDel: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/del`, // 删除关键信息
  batchImportKnowledgePointModelFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-import-knowledge-point/model-file`, // 获取批量导入关键信息模板下载地址
  validBatchImportKnowledgePoint: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-knowledge-point`, // 校验批量导入关键信息模板文件
  treeNodeWithSelect: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/tree-node-with-select`, // 根据ExamId查询关键信息数据

  liveAuditList: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/live-audit-list`, // 厂家直播审核分页列表
  // 驳回代表创建直播的申请
  rejectLiveCreate: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/reject-live-create`,
  // 批量通过代表创建直播/会议的申请
  batchSuccessLiveCreate: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/batch-success-live-create`,
  // 通过代表创建直播/会议的申请
  successLiveCreate: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/success-live-create`,
  exportLiveAuditData: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/export-live-audit-data`, // 导出所有直播审核列表数据
  getLiveAuditDetail: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/get-live-audit-detail`, // 获取直播审核详情
  selectSupportReverseTag: `${VUE_APP_GATEWAY_QXS}/factory/select-support-reverse/tag`, // 选择使用支持反向操作
  knowledgePointCount: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/count`, // 根据关键信息Id查询引用数量
  editEnable: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/edit-enable`, // 编辑关键信息开启状态
  batchBind: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/batch-bind`, // 批量绑定关键信息
  certifyRule: `${VUE_APP_GATEWAY_QXS}/common/live/v1/get-factory-live-default-review-sec`,
  queryHistoryList: `${VUE_APP_GATEWAY_QXS}/factory/v1/infoCollection/queryHistoryList`, // 查询用户历史表单列表
  getFormInfoById: `${VUE_APP_GATEWAY_QXS}/factory/v1/infoCollection/getFormInfoById`, // 根据表单id查询表单信息，使用历史表单的时候用到
  delFormInfoById: `${VUE_APP_GATEWAY_QXS}/factory/v1/infoCollection/delFormInfoById`, // 根据表单id删除历史表单
  infoCollectionRecords: `${VUE_APP_GATEWAY_QXS}/org/live/v1/download/info-collection-records`, // 下载信息采集表单
  addOrUpdateInfoCollection: `${VUE_APP_GATEWAY_QXS}/org/live/v1/addOrUpdateInfoCollection`, // 创建或者编辑信息采集表单
  // *********************** 公共资源库相关 start ****************************
  sceneShare: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/share`, // 分享活动给子组织或同级组织或公共资源库
  cancelSceneShare: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/share-to-public-resource-lib`, // 取消分享公共资源库
  cancelMaterialShare: `${VUE_APP_GATEWAY_QXS}/common/v1/resource/share-to-pub-res-lib-or-cancel`, // 取消分享公共资料
  scenePublicResourceLibActivityList: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/activity/list`, // 获取公共资源库项目分页列表
  scenePublicResourceLibLiteratureList: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/literature/list`, // 获取公共资源库资料分页列表
  addToMyLib: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/literature/add-to-my-lib`, // 添加公共资源库的素材至我的素材库
  literatureDataList: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/literature/data/list`, // 获取公共资源库学术资料数据分页列表
  literatureDataDownload: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/literature/data/download`, // 下载公共资源库学术资料数据
  // *********************** 公共资源库相关 end ****************************

  sceneAuditList: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/scene-audit-list`, // 学术任务审核列表
  rejectSceneTaskApply: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/batch-reject-scene-task-apply`, // 厂家批量驳回代表申请的学术任务资源
  passSceneTaskApply: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/batch-success-and-assign-scene-task`, // 厂家批量通过代表申请的学术任务资源
  passAssignSceneTaskApply: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/success-and-assign-scene-task`, // 厂家单个通过代表申请的学术任务资源
  exportSceneAuditData: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/export-scene-audit-data`, // 导出筛选的审核数据
  sceneAuditDetail: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/scene-audit-detail`, // 导出筛选的审核数据
  repLastMonthsSceneTask: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/rep-last-months-scene-task-audit-list`, // 近三个月的任务申请列表
  repLastMonthsLiveTask: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/rep-last-months-live-audit-list`, // 近三个月的直播、会议申请列表
  getSceneShareStatus: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/is-shared-to-public-resource-lib`, // 查询项目是否被分配到了公共资源库
  getSimpleAccountBalance: `${VUE_APP_GATEWAY_QXS}/factory/v1/account/get-simple-account-balance`, // 获取账户余额
  updateSceneTaskAudit: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/update-scene-audit-resource`, // 厂家在发起委托前更新任务申请资源信息

  // 批量支付，上传接口
  uploadBatchPayLiveFee: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-pay-live-fee/model-file`,
  // 批量支付，模版下载接口    获取下载批量支付视频采购费模板
  downBatchPayLiveFee: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-pay-live-fee/model-file`,
  // 批量支付，确认导入接口
  confirmBatchPayLiveFee: `${VUE_APP_GATEWAY_QXS}/org/live/v1/doctor/batch-pay-fee`,
  // 校验批量审批学术任务模板
  uploadBatchSceneTaskAudit: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-scene-task-audit/model-file`,
  // 获取下载批量审批学术任务模板
  downBatchSceneTaskAudit: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-scene-task-audit/model-file`,
  // 批量审批学术，确认导入接口
  confirmBatchSceneTaskAudit: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/batch-audit-scene-task`,

  // 委托
  getAcademicEntrustList: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/academic-survey-list`, // 委托服务-学术调研委托服务
  exportAcademicEntrustList: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/academic-survey-list/export`, // 委托服务-学术调研委托服务列表-导出
  getEntrustDetail: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/delegation-detail`, // 委托详情
  batchOperateEntrust: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/batch-operate-delegation`, // 委托服务-学术调研委托服务列表-批量操作（批量结束/删除委托）
  restartAcademicEntrust: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/delegation-repay`, // 重新发起委托重新支付
  cancelAcademicEntrust: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/cancel-delegation`, // 取消委托
  getEndEntrustGift: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/end-delegation-gift`, // 查询撤回委托可撤回多少积分
  getReviewSuccessList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/review-success-list`, // 审核成功待确认列表
  batchPurchasLiveList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/batch-purchase`, // 审核成功待确认-批量采购
  confirmPurchasLiveList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/confirm-purchase`, // 审核成功待确认-确认/拒绝采购
  exportReviewSuccessList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/review-success-list/export`, // 审核成功待确认详情-导出
  getPaidLiveList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/paid-list`, // 已购买列表
  exportPaidLiveList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/paid-list/export`, // 已购买列表-导出
  saveDelegationForm: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/save-delegation-form`, // 保存委托表单
  historyDelegationList: `${VUE_APP_GATEWAY_QXS}/common/v1/list-activity-history-delegation-quest`, // 获取某个活动的历史委托问卷列表
  putQuestInTrash: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/put-quest-in-trash`, // 将调研问卷删除
  getDelegationDetail: `${VUE_APP_GATEWAY_QXS}/common/v1/get-activity-delegation-quest-detail`, // 获取某个活动的委托问卷详情
  getAssignFilterItem: `${VUE_APP_GATEWAY_QXS}/factory/v2/doctor/get-direct-assign-filter-item`, // 获取定向分配医生时用户标签筛选项

  // ---------------------------- 拜访管理 --------------------------
  // 拜访管理列表
  customerVisitList: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/list`,
  // 拜访管理详情
  customerVisitDetail: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/detail`,
  // 拜访字段列表
  customerVisitField: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/field-setting`,
  // 拜访字段列表
  customerVisitUpdateField: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/update/field-setting`,
  // 拜访设置详情
  customerVisitSetting: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/setting`,
  // 拜访设置详情
  customerVisitUpdateSetting: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/update/setting`,
  // 拜访设置详情
  customerVisitCard: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/clock-in-setting`,
  // 拜访设置详情
  customerVisitUpdateCard: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/update/clock-in-setting`,

  // ---------------------------- 拜访管理 end --------------------------
  // ---------------------------- 标签管理 ------------------------------
  // 获取关键信息数据
  getProductKnowledgePointList: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/list-by-data-id`,
  // 保存关键信息数据
  saveProductKnowledgePointList: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/v2/save`,
  // 删除关键信息数据
  delProductKnowledgePointList: `${VUE_APP_GATEWAY_QXS}/org/knowledge-point/v2/del`,
  // 批量设置标签关键信息
  batchSetTag: `${VUE_APP_GATEWAY_QXS}/common/v1/material/batch-set-tag-and-knowledge-point`,
  // ---------------------------- 标签管理 end --------------------------
  // 客户分级
  selectClientGradeList: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/select-clientGrade-list`,
  // 获取产品标签
  productTagList: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/product-tag-list`,
  // 新建分级
  saveClientGrade: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/save-update-client-grade`,
  // 删除客户分级
  deleteClientGrade: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/delete-client-grade`,
  // 查询分级记录待更新列表
  selectClientGradeRecordList: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/select-clientGradeRecord-list`,
  // 获取问卷列表
  selectQuestionnairesList: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/select-questionnaires-list`,
  // 查询我及我以下分级客户信息
  selectDoctorClientInfoList: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/select-doctor-clientInfo-list`,
  // 客户分级查看详情
  selectClientGradeDetail: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/select-client-grade-detail`,
  // 删除分级记录/批量删除
  deleteClientGradeRecord: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/delete-clientGradeRecord`,
  // 编辑分级记录
  editClientGradeRecord: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/edit-clientGradeRecord`,
  // 批量更新
  updateClientGradeRecordBatch: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/update-client-grade-record-batch`,
  // 分级统计数据
  clientGradeRecordStatistic: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/clientGradeRecord-statistic`,
  // 查询客户分级基础信息
  getBaseClientGrade: `${VUE_APP_GATEWAY_QXS}/common/get-base-clientGrade`,
  // 客户分级下载模版
  exportClientGradeTemplate: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/export-clientGrade-template`,
  // 客户分级导入数据
  importFromGradeExcel: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/import-from-grade-excel`,
  // 客户分级记录下载
  clientGradeRecordDownLoad: `${VUE_APP_GATEWAY_QXS}/factory/client/grade/clientGradeRecord-downLoad`,
  // 组织新建播客时获取医生的基础信息
  doctorBaseInfo: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/doctor/base-info`,
  // 组织编辑播客时显示的基础和分级相关信息
  editSpeakerInfo: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/edit-speaker-info`,
  // 组织（批量/单个）新建/编辑播客
  batchSaveOrUpdate: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/batch-save-or-update`,
  // 播客企业级别字典列表
  orgGradeDicts: `${VUE_APP_GATEWAY_QXS}/public/v1/speaker/org-grade/dicts`,
  // 播客平台级别字典列表
  platformGradeDicts: `${VUE_APP_GATEWAY_QXS}/public/v1/speaker/platform-grade/dicts`,
  // 获取批量导入组织播客分级模板下载地址
  importCommonDownModel: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-import-excel/download-url-by-data-type`,
  // 校验批量导入组织播客分级信息文件
  uploadBatchImportOrgGradeSpeakerModel: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-org-grade-speaker`,
  // 批量导入组织分级播客数据
  confirmBatchImportOrgGradeSpeakerModel: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/batch-import-by-excel`,
  // 查看组织播客晋升记录详情
  promotionDetail: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/promotion/detail`,
  // 播客管理
  getGradeSpeakerList: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/list`, // 组织播客列表
  deleteGradeSpeaker: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/delete`, // 删除分级播客
  exportGradeSpeaker: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/list/download`, // 导出分级播客
  getGradeSpeakerPromotionList: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/promotion/list`, // 分级播客晋升列表
  exportGradeSpeakerPromotion: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/promotion/list/download`, // 导出分级播客晋升列表
  batchProcessGradeSpeaker: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/batch-audit`, // 批量审核通过/拒绝播客晋升记录
  cancelGradeSpeakerPromotion: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/cancel-audit`, // 取消播客晋升
  getGradeDistributionInfo: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/grade-distribution-info`, // 播客漏斗数据
  getGradeLiveDistributionInfo: `${VUE_APP_GATEWAY_QXS}/org/grade-speaker/live-type-distribution-info`, // 播客晋升数据
  getPodcastDetail: `${VUE_APP_GATEWAY_QXS}/common/v1/medical-podcast/preview`, // 播客详情
  getPodcastSave: `${VUE_APP_GATEWAY_QXS}/factory/podcast/v1/podcast/edit`, // 编辑播课详情
  challengeDelete: `${VUE_APP_GATEWAY_QXS}/factory/podcast/v1/challenge/delete`, // 删除征集活动
  challengeCllose: `${VUE_APP_GATEWAY_QXS}/factory/podcast/v1/challenge/close`, // 关闭活动
  podcastReviewFail: `${VUE_APP_GATEWAY_QXS}/factory/medical-podcast/v1/podcast/review-fail`, // 内容不合适，审核拒绝
  podcastReviewPass: `${VUE_APP_GATEWAY_QXS}/factory/medical-podcast/v1/podcast/pay-fee`, // 内容合适，审核通过

  // 策略项目 ====================
  projectList: `${VUE_APP_GATEWAY_QXS}/factory/project/project-list`, // 查询项目列表
  strategyList: `${VUE_APP_GATEWAY_QXS}/factory/strategy/strategy-list`, // 查询策略列表
  addOrUpdateStrategy: `${VUE_APP_GATEWAY_QXS}/factory/strategy/addOrUpdateStrategy`, // 新增/编辑策略
  getStrategyDetail: `${VUE_APP_GATEWAY_QXS}/factory/strategy/getStrategyDetail`, // 查询策略详情
  changeStrategyStatus: `${VUE_APP_GATEWAY_QXS}/factory/strategy/changeStrategyStatus`, // 修改策略状态
  scenePublicResourceLibProjectList: `${VUE_APP_GATEWAY_QXS}/org/scene-public-resource-lib/project-list`, // 查询公共资源库学术项目列表
  changeProjectStatus: `${VUE_APP_GATEWAY_QXS}/factory/project/change/projectStatus`, // 结束项目
  v1TreeList: `${VUE_APP_GATEWAY_QXS}/organization/v1/tree-list`, // 部门架构列表
  createOrEditProject: `${VUE_APP_GATEWAY_QXS}/factory/project/crateOrEditProject`, // 创建或者编辑项目
  detailBaseInfo: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/base-info`, // 项目详情基础信息
  orgExecData: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/org-exec-data`, // 项目详情各组织的项目执行数据
  conceptualCoverageList: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/conceptual-coverage-list`, // 项目详情观念覆盖活动分页列表
  speakerTrainingList: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/speaker-training-list`, // 项目详情播客培养活动分页列表
  stopOrUseActivity: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/stop-or-use-activity`, // 停用/启用某个项目下的某个类型的活动
  orgActiveBaseInfoList: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/org-activity-base-info-list`, // 项目详情-项目添加观念覆盖类型活动的基础信息分页列表
  addConceptualCoverageActivity: `${VUE_APP_GATEWAY_QXS}/factory/project/detail/add-conceptual-coverage-activity`, // 项目详情-观念覆盖-添加活动
  activityProjectRelation: `${VUE_APP_GATEWAY_QXS}/factory/project/activity-project-relation`, // 获取活动是否关联项目的信息
  getProjectEditDetail: `${VUE_APP_GATEWAY_QXS}/factory/project/getProjectEditDetail`, // 获取项目编辑中的详情
  modelFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/batch-import-project-doctor/model-file`, // 获取下载批量分配医生模板
  batchImportProjectDoctorModelFile: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/valid/batch-import-project-doctor/model-file`, // 校验批量项目目标医生模板
  delegationFormInfo: `${VUE_APP_GATEWAY_QXS}/org/delegation/v1/delegation-form-info`, // 获取问题调研包表单
  assignableRepresent: `${VUE_APP_GATEWAY_QXS}/factory/assignable-represent`, // 获取可分配的代表
  factoryDoctorRepresentCount: `${VUE_APP_GATEWAY_QXS}/organization/v2/factory-doctor-represent-count`, // 查询一组厂家及其以下的部门人数和绑定医生数,支持医生过滤
  excelResultData: `${VUE_APP_GATEWAY_QXS}/common/excel/v1/get/excel-result-data`, // 通用获取Excel校验成功数据
  queryStatus: `${VUE_APP_GATEWAY_QXS}/factory/video/parse/query-status`, // 查看视频解析状态
  getRepresentLiveRecordList: `${VUE_APP_GATEWAY_QXS}/org/live/v1/represent-view-records`, // 查看视频解析状态

  fuzzyMatchList: `${VUE_APP_GATEWAY_QXS}/factory/v1/exam/fuzzy-match-list`, // 模糊匹配列表
  getSimilarList: `${VUE_APP_GATEWAY_QXS}/factory/v2/exam/similar-list`, // 相似题目列表
  getSceneTag: `${VUE_APP_GATEWAY_QXS}/factory/v1/tag/product/get-by-scene` // 获取场景标签
}
